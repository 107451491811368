<template>
    <div style = 'margin: 5% 2%; padding-top: 10px;'>
        <v-card flat style='padding-top: 30px;' max-width="1000">
            <div class="container" style="display:inline-flex; width:100%;">
                <div class="row" style="display:inline-block;">
                    <div v-for="(redirectButton,redirectKey) in redirectionButtons" :key="redirectKey" class = 'admin-panel' style='display:inline-block; margin-right:auto; margin-top:auto; vertical-align:top'>
                        <v-card-text v-ripple style='width:100%; height:100%;'>
                            <div @click="redirectButton.onClick" style = 'text-align: center; width:100%; height:100%;'>
                                <v-icon  class = 'admin-panel-icon-resize'  color="primary">  {{ redirectButton.icon }} </v-icon>
                                <div class = "admin-panel-text-resize">{{ redirectButton.name }}</div>
                                <div class = "admin-panel-text-under-resize">{{ redirectButton.description }}</div>  
                            </div>
                        </v-card-text>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'AdminPanel',
    data() {
        return{
            redirectionButtons:[
                {
                    icon:"mdi-account-multiple",
                    name: "{L Użytkownicy }",
                    description:"{L Dodaj lub zarządzaj użytkownikami }",
                    link:"/admin/users",
                    onClick:() => {},
                },
                {
                    icon:"mdi-book",
                    name:"{L Słowniki }",
                    description:"{L Definiuj własne typy i statusy }",
                    link:"/admin/dictionaries",
                    onClick:() => {},
                },
                {
                    icon:"mdi-key-variant",
                    name:"{L Uprawnienia }",
                    description:"{L Zarządzaj dostępami do modułów }",
                    link: "/admin/permissions",
                    onClick:() => {},
                },
            ]

        };
    },
    mounted() {
        if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
            return
		}
        this.$emit('set-title', this.lview.adminPanelTitle);
        this.$emit("set-state", "default");
        
         // setting permissions
         let userPermissions = []
        if(localStorage.getItem('permissions') !== null){
			userPermissions = JSON.parse(localStorage.permissions);
		}

        if (!userPermissions.includes("admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return
		}

        // users
        this.redirectionButtons[0].onClick = () => {
            if( this.redirectionButtons[0].link != null ){
                this.$router.push(this.redirectionButtons[0].link);
            }      
        };
        this.redirectionButtons[0].name = this.lview.users;
        this.redirectionButtons[0].description = this.lview.usersDescription;

        // dictionaries
        this.redirectionButtons[1].onClick = () => {
            if( this.redirectionButtons[1].link != null ){
                this.$router.push(this.redirectionButtons[1].link);
            }      
        };
        this.redirectionButtons[1].name = this.lview.dictionaries;
        this.redirectionButtons[1].description = this.lview.dictionariesDescription;
        
        // permissions
        this.redirectionButtons[2].onClick = () => {
            if( this.redirectionButtons[2].link != null ){
                this.$router.push(this.redirectionButtons[2].link);
            }      
        };
        this.redirectionButtons[2].name = this.lview.permissions;
        this.redirectionButtons[2].description = this.lview.permissionsDescription;

    },
    computed: {
        lview: { get: function () { return this.$t('views.adminPanel'); } },
    },
    methods: {

    }
}
</script>
<style>
.admin-panel {
        width:250px;
        height:200px;
    }
@media only screen and (max-width: 800px){
        .admin-panel {
            width:140px;
            height:120px;
        }
    }

.admin-panel:hover {
        background-color: lightgrey;
}
.admin-panel-text-resize {
        font-size: 15px;
        font-weight: bold;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-resize {
            font-size: 12px;
            font-weight: bold;
        }
    }

.admin-panel-text-under-resize {
        font-size: 12px;
        margin: 0px;
        height:50px;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-under-resize {
            font-size: 8px;
            margin: 0px;
            height:30px;
        }
    }

.admin-panel-icon-resize {
        font-size: 70px !important;
    }
@media only screen and (max-width: 800px){
        .admin-panel-icon-resize {
            font-size: 40px !important;
        }
    }

.admin-panel-text-top-resize {
        font-size: 15px;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-top-resize {
            font-size: 13px;
        }
    }

.admin-panel-icon-top-resize {
        font-size: 30px !important;
    }
@media only screen and (max-width: 800px){
        .admin-panel-icon-top-resize {
            font-size: 20px !important;
        }
    }

.stats {
        background-color: white;
        color: black;
    }
@media only screen and (max-width: 800px){
        .stats {
            background-color: white;
            color: black;
            margin-bottom:5%;
        }
    }
</style>
<style scoped>
.app-router-component {
    overflow-y: hidden !important;
}
</style>